import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class BasemethodsService {
  loginUser: any;

  constructor(private _baseS: BaseService) {
    this.loginUser = JSON.parse(localStorage.getItem(environment.loginUserStorageKey));
  }

  get(controller: string, callback) {
    this._baseS.sendGetRequestO(controller + "/GetAll?companyid=" + this.loginUser["selectedCompanyId"]).subscribe(data => {
      callback(data["data"]);
      // if (data["flag"]) {
      //   this._baseS.onSuccess(data["message"]);
      // }
    });
  }

  async getMeasurement() {
    const v = await this._baseS.sendGetRequestP("Measurment/GetAll?companyid=" + this.loginUser["selectedCompanyId"]);
    return v["data"];
  }
  async getLocations(id: number) {
    const v = await this._baseS.sendGetRequestP("UserLogin/getlocation?id=" + id);
    return v["data"];
  }
  async getSupplier() {
    const v = await this._baseS.sendGetRequestP("Supplier/GetAll?companyid=" + this.loginUser["selectedCompanyId"]);
    return v["data"];
  }
  async getMarketingAgent() {
    const v = await this._baseS.sendGetRequestP("SalesAgent/GetAll?companyid=" + this.loginUser["selectedCompanyId"]);
    return v["data"];
  }
  async getProjects() {
    const v = await this._baseS.sendGetRequestP("ProjectsMaster/GetAll?companyid=" + this.loginUser["selectedCompanyId"]);
    return v["data"];
  }
  async getPropertyType() {
    const v = await this._baseS.sendGetRequestP("PropertyTypes/GetAll?companyid=" + this.loginUser["selectedCompanyId"]);
    return v["data"];
  }
  async getSalesPerson() {
    const v = await this._baseS.sendGetRequestP("SalesPerson/GetAll?companyid=" + this.loginUser["selectedCompanyId"]);
    return v["data"];
  }
}
